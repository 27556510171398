import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { Breadcrumb } from '@sentry/angular';
import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root',
})
export class SentryService {
	constructor() {
		this.initSentry();
	}

	private initSentry(): void {
		Sentry.init({
			dsn: environment.sentryDsn,
			environment: environment.production ? 'production' : 'development', // set environment value based on whether it's production or not
			integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
			// DO NOT CHANGE THIS VALUE, IT IS USED TO IDENTIFY THE BUILD IN SENTRY
			release: 'RELEASE_BUILD_ID',
			// Performance Monitoring
			tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
			// Session Replay
			replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
			replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
			ignoreErrors: ['Non-Error exception captured'],
			beforeSend(event) {
				return event;
			},
		});
	}

	setUserAndOrganization(id: string, organizationId: string): void {
		this.setUserId(id);
		this.setOrganizationId(organizationId);
	}

	setUserId(id: string): void {
		Sentry.setUser({ id });
	}

	setTag(key: string, value: string): void {
		Sentry.setTag(key, value);
	}
	setOrganizationId(id: string): void {
		Sentry.setTag('organization_id', id);
	}

	captureMessage(message: string, level: Sentry.SeverityLevel = 'info'): void {
		Sentry.captureMessage(message, level);
	}

	addBreadcrumb(message: string, category = 'default', data?: Record<string, any>): void {
		const breadcrumb: Breadcrumb = {
			message: message,
			category: category,
			data: data || {},
		};
		Sentry.addBreadcrumb(breadcrumb);
	}

	//adds the http response message as additional data to the Sentry error
	addHttpResponseMessage(message) {
		Sentry.setExtra('HttpResponse', message);
	}

	//adds the component name as additional data to the Sentry error
	addComponentName(componentName: string): void {
		Sentry.setExtra('Component', componentName);
	}

	addBankScrapingIdentifier(identifier: string): void {
		Sentry.setExtra('Bank scraping identifier', identifier);
	}

	captureInitScrapingRequest(componentName, bankRequest) {
		this.addComponentName(componentName);
		this.addBreadcrumb('Bank request', 'scraping', bankRequest);
		this.addBankScrapingIdentifier(bankRequest.bank ?? bankRequest.bank_identifier);
		this.captureMessage('Init scraping request');
	}

	captureInitScrapingException(error, componentName, bankRequest): void {
		this.addComponentName(componentName);
		this.addBreadcrumb('Bank request', 'scraping', bankRequest);
		this.addBankScrapingIdentifier(bankRequest.bank ?? bankRequest.bank_identifier);
		this.addHttpResponseMessage(error.message);
		this.captureMessage('Initialize scraping session error', 'error');
	}

	captureEnableBankingException(componentName, params): void {
		this.addComponentName(componentName);
		this.addBreadcrumb('Enable banking params', 'scraping', params);
		this.captureMessage('Enable banking error', 'error');
	}

	captureLegacyScrapingException(componentName, hintCode): void {
		this.addComponentName(componentName);
		this.addBreadcrumb('Bank request', 'scraping', { hintCode });
		this.captureMessage('Legacy scraping error', 'error');
	}
}
