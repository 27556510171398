import { Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
	selector: 'app-radio-item',
	templateUrl: './radio-item.component.html',
	styleUrl: './radio-item.component.scss',
})
export class RadioItemComponent implements OnInit, ControlValueAccessor {
	@Input() value: any;
	@Input() hideBorder: false;
	@Input() description: any;
	@Input() text: string;
	@Input() collapsible = false;
	model: any;

	@Output() optionClick = new EventEmitter<void>();

	onTouched = () => {};
	onChanged = (_: any) => {};

	constructor(@Self() @Optional() public control: NgControl) {
		this.control.valueAccessor = this;
	}
	ngOnInit() {
		this.control.control.valueChanges.subscribe(value => {
			if (this.model === value) return;
			this.writeValue(value);
		});
	}

	writeValue(value: any): void {
		this.model = value;
	}

	registerOnChange(fn: any): void {
		this.onChanged = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	onChange() {
		this.model = this.value;
		this.onChanged(this.model);
	}

	hasErrors() {
		return (this.control.dirty || this.control.touched) && this.control.errors;
	}

	isChecked() {
		return JSON.stringify(this.model) === JSON.stringify(this.value);
	}
}
